<template>
    <div class="page-table musteriTanim  mainDiv mb-30" id="affix-container">
        <div class="animated fadeInUp card-base baslikBg">
            Cari Güncelle
        </div>
        
        <el-row :gutter="10" v-loading="loading || addLoading"
            :element-loading-text='loading ? "Cari Detay Bilgileri Getiriliyor. Lütfen Bekleyiniz.": "Cari Bilgileri Güncelleniyor. Lütfen Bekleyiniz."'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
            <el-form status-icon :model="kurumForm" :rules="rulesKurum" ref="kurumForm" class="demo-ruleForm">
                <el-col :lg="18" :md="18" :sm="24" :xs="24">                        
                        <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft pb-20 pt-10" >
                            <el-row>
                                <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label="Cari Adı" prop="kurumAdi">:
                                        <el-input size="medium"
                                            v-model="kurumForm.kurumAdi"
                                            :placeholder="'Cari Adı Giriniz'"></el-input>
                                    </el-form-item>
                                </el-col>                             
                              
                                <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                                    <el-form-item label="Cari Durumu" prop="kurumDurumu">:
                                        <br>
                                        <!--
                                            <el-checkbox size="medium" label="1" v-model="kurumForm.gelen" border>Gelen</el-checkbox>
                                            <el-checkbox size="medium" label="2" v-model="kurumForm.giden" border>Giden</el-checkbox>
                                            -->

                                            <div style="width: 200px;">
                                                <el-switch
                                                    v-model="kurumForm.kurumDurumu"
                                                    active-color="#048484"
                                                    inactive-color="#048484"
                                                    active-text="Giden"
                                                    inactive-text="Gelen"
                                                    :active-value="2"
                                                    :inactive-value="1">
                                                </el-switch>
                                            </div>


                                    </el-form-item>
                                </el-col> 
                            </el-row>                                                    
                        </div>                   

                </el-col>

                <el-col :lg="6" :md="6" :sm="24" :xs="24">
                    <el-collapse class="yanForm animated fadeInUp" v-model="activeCollapse">
                        <el-collapse-item class="card-shadow--medium" name="8" :title='"Güncelle"'>
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24">
                                    <el-button size="mini" v-on:click="kurumGuncelle('kurumForm')" class="onayBtn" type="primary">
                                        <label>Güncelle</label>
                                    </el-button>
                                    &nbsp;
                                <el-popover placement="bottom" width="310" v-model="visibleSil">
                                    <p>{{$t('src.views.apps.messages.clearFormAlert')}}</p>
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="primary" @click="resetForm('kurumForm')">
                                            {{$t('src.views.apps.genel.yes')}}
                                        </el-button>
                                        <el-button size="mini" @click="visibleSil = false;">
                                            {{$t('src.views.apps.genel.no')}}
                                        </el-button>
                                    </div>
                                    <el-button size="mini" slot="reference">
                                        {{$t('src.views.apps.genel.temizle')}}
                                    </el-button>
                                </el-popover>
                                </el-col>
                            </el-row>
                        </el-collapse-item>
                    </el-collapse>
                </el-col>
            </el-form>
        </el-row>
    </div>
</template>
<script>
    import ClassicEditor from '../../../../node_modules/ckeditor5-build-classic';
    import draggable from "vuedraggable";

    import kurumService from '../../../WSProvider/KurumService'

    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'
    import JQuery from 'jquery';

    let $ = JQuery;

    var moment = require('moment');

    export default {
        name: "CariGuncelle",
        components: {
            draggable
        },

        computed: {
            storeData(){
                return this.$store.getters.getKurumUpdateData
            }
        },
        watch: {
            storeData(val){
                this.kurumForm.kurumID = this.$store.getters.getKurumUpdateData
                this.getKurumDetay();
            }
        },
        
        mounted() {
            this.kurumForm.kurumID = this.$store.getters.getKurumUpdateData
            this.getKurumDetay();
        },
        
        data() {
            return {
                radio: ['1'],
                kurumData: {},
                selectedOption: ['Gelen', 'Giden'],
                loading : false,                
                addLoading: false,
                editor: ClassicEditor,
                editorConfig: {
                    language: 'tr',
                },
                activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', "11"],
                visibleSil: false,
                disabled: false,
                kurumForm: {
                    kurumAdi: '',
                    gelen: "",
                    giden: "",
                    kurumDurumu: '',         
                    durum: '1',
                },
                rulesKurum: {                    
                    kurumAdi: [{
                        required: true,
                        message: "Lütfen Kurum Adı Giriniz",
                        trigger: 'blur'
                    }],   /* 
                    kurumDurumu: [{
                        required: true,
                        message: "Lütfen Kurum Durumu Seçiniz",
                        trigger: 'blur'
                    }],     */            
                },
            }
        },
        methods: {

            getKurumDetay(){
                try{
                    this.loading = true;
                    kurumService.kurumDetay(this.kurumForm.kurumID).then(response => {
                        if(response.status == 200){
                            localStorage.setItem("userDataDemirag", response.token);

                            this.kurumForm.kurumAdi = response.data.kurumAdi
                            this.kurumForm.kurumDurumu = response.data.kurumDurumu == 1 ? 1 : 2 
                            this.kurumForm.durum = response.data.durum
                            
                            this.kurumData = response.data
                        }
                        this.loading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }else{
                            //notification.Status(503, this)
                        }
                        this.loading = false;
                    })
                }catch(e){
                    this.loading = false;
                }
            },

            kurumGuncelle(formName) {
                this.$refs[formName].validate((valid, errors) => {
                    if (valid) {
                        this.$confirm("Cariyi güncellemek istediğinize emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
                            confirmButtonText: this.$t("src.views.apps.genel.yes"),
                            cancelButtonText: this.$t("src.views.apps.genel.no"),
                            type: 'warning'
                        }).then(() => {
                            this.addLoading = true;

                            kurumService.kurumGuncelle(this.kurumForm.kurumID, this.kurumForm.kurumAdi, this.kurumForm.kurumDurumu).then((response) => {
                                if(response.status == 200){
                                    localStorage.setItem("userDataDemirag", response.token)
                                    notification.Status("success", this, response.msg)                                    
                                    EventBus.$emit("cariList", true)
                                    functions.sayfaKapat("cariguncelle", this);
                                    this.resetForm(formName)
                                }
                                this.addLoading = false;
                            }).catch(err => {
                                if(err.responseJSON){
                                    let error = err.responseJSON
                                    if(error.status == 401){
                                        notification.Status(150, this);
                                    }
                                    else notification.Status("errorMsg", this, error.errMsg)
                                }else{
                                    //notification.Status(503, this)
                                }
                                this.addLoading = false;
                            })
                        }).catch((e) => {})
                    }else{
                        for(var k in errors){
                            errors[k].forEach((element) => {
                                notification.Status( "warning", this, element.message);
                            })  
                            this.addLoading = false
                        }
                    }
                })
            },

            resetForm(formName) {
                this.$refs[formName].resetFields();
            },

        }
    }

</script>
